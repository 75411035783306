.ant-select,
.ant-input {
  width: auto;
  min-width: 70px;
  max-width: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ant-switch{
  width: auto;
  max-width: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.input-parameters {
  width: auto;
}

.ant-picker {
  min-width: 260px;
}

.key_time_range {
  min-width: 358px;
}

.local-parameters .ant-space-item {
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.local-parameters {
  display: inline-block;
  width: 100%;
}

.ant-card-grid {
  box-shadow: none;
  border: 1px solid rgba(208, 208, 208, 0.25);
}

.key_time_range {
  min-width: 358px;
}

.ant-card {
  min-width: 260px;
}

.ant-card-body {
  min-height: 100px;
}
